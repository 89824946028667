import React, { useState } from "react";
import cx from "classnames";
import Photo from "@components/photo";
import Icon from "@components/icon";
import Phrase from "@components/phrase";
import BlockContent from "@components/block-content";
import CountUp from "react-countup";
const Grid = ({ data = {} }) => {
  const { style, phrase, backgroundColor, gridBlocks } = data;
  const [openGridBlock, setOpenGridBlock] = useState(false);

  return (
    <section
      className={cx(
        "grid-section",
        "py-gutter",
        "relative",
        "z-20",
        `bg-${backgroundColor?.value}`,
        {
          "my-2 mx-2": style == "no-gutters",
        }
      )}
      data-theme={backgroundColor?.title.toLowerCase()}
    >
      {phrase && (
        <>
          <div
            className={cx(
              "grid",
              "text-center",
              "pt-10",
              "hidden",
              "sm:grid",
              "mb-5",
              "site-container"
            )}
          >
            <Phrase
              phrase={phrase}
              typeStyle={"text-lg-1-mobile md:text-lg-1"}
            />
          </div>
          <div
            className={cx(
              "grid",
              "text-center",
              "pt-10",
              "sm:hidden",
              "site-container"
            )}
          >
            <Phrase
              phrase={phrase}
              vertical={true}
              dividerMaxWidth="100px"
              typeStyle={"text-lg-1-mobile md:text-lg-1"}
            />
          </div>
        </>
      )}
      {style == "default" && (
        <div
          className={cx("grid", "grid-cols-2", "m-[10px]", "gap-[5px]")}
          data-theme={backgroundColor?.title.toLowerCase()}
        >
          {gridBlocks.map((block, idx) => {
            return (
              <div
                key={idx}
                className={cx(
                  "col-span-2",
                  "sm:col-span-1",
                  "grid-block",
                  "rounded-[3px]",
                  `bg-${block.backgroundColor.value}`
                )}
                style={{ backgroundColor: block.backgroundColor?.value }}
              >
                <div className={cx("max-w-[501px]", "mx-auto")}>
                  <div
                    className={cx(
                      "text-lg-2-mobile",
                      "md:text-lg-2",
                      "text-center",
                      "p-10"
                    )}
                  >
                    {block.title}
                  </div>
                  <div className={cx("block-image")}>
                    {block.image && (
                      <Photo
                        photo={block.image}
                        width={1200}
                        layout="static"
                        bgColor={block.backgroundColor?.title.toLowerCase()}
                      />
                    )}
                  </div>
                  <div
                    className={cx(
                      "mx-auto",
                      "text-large",
                      "text-center",
                      "font-sansRegular",
                      "p-10"
                    )}
                  >
                    <BlockContent blocks={block.body} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {style == "no-gutters" && (
        <div
          className={cx(
            "flex",
            "flex-wrap",
            "flex-col",
            "sm:flex-row",
            "my-[0px]",
            "gap-[0px]",
            "justify-center",
            "site-container",
            {
              "py-0 md:py-[50px]": !phrase,
            }
          )}
          style={{ backgroundColor: backgroundColor?.value }}
        >
          {gridBlocks.map((block, idx) => {
            return (
              <div
                key={idx}
                className={cx(
                  "w-full",
                  "md:w-1/3",
                  "grid-block",
                  "py-10",
                  "px-6",
                  `bg-${block?.backgroundColor?.value}`,
                  {
                    "sm:w-[40%]": gridBlocks.length == 2,
                    "md:w-[60%] pt-0": gridBlocks.length == 1,
                  }
                )}
                data-theme={block.backgroundColor?.title.toLowerCase()}
              >
                {block.titlePlacement == "aboveImage" && block.title && (
                  <div
                    className={cx(
                      "text-lg-3",
                      "text-center",
                      "mt-5",
                      "pb-5",
                      "md:min-h-[90px]"
                    )}
                  >
                    {block.title}
                  </div>
                )}
                <div className={cx("flex", "align-bottom")}>
                  <div
                    className={cx(
                      "max-w-[100%]",
                      "w-full",
                      "mx-auto",
                      "mt-auto",
                      "flex",
                      "flex-col",
                      {
                        "aspect-[5/4--disabled]": gridBlocks.length !== 1,
                        "aspect-[3/2--disabled]": gridBlocks.length == 1,
                      }
                    )}
                  >
                    {block.image && (
                      <Photo
                        photo={block.image}
                        width={1200}
                        layout="static"
                        className={cx(
                          "mt-auto",
                          "mb-2",
                          "w-full",
                          "h-full",
                          "contain"
                        )}
                      />
                    )}
                  </div>
                </div>
                {block.titlePlacement !== "aboveImage" && block.title && (
                  <div
                    className={cx(
                      "text-subheader",
                      "uppercase",
                      "text-center",
                      "mt-5",
                      "pb-3",
                      "border-b",
                      "font-sansBook"
                    )}
                  >
                    {block.title}
                  </div>
                )}
                <div
                  className={cx(
                    "max-w-[100%]",
                    "mx-auto",
                    "text-large",
                    "font-sansRegular",
                    "text-center",
                    "py-5",
                    {
                      "border-t": block.titlePlacement == "aboveImage",
                    }
                  )}
                >
                  <div className={cx("figure", "text-lg-3", "tabular-nums")}>
                    {block.figure && !block.animateFigure && (
                      <span>{block.figure}</span>
                    )}

                    {block.figure &&
                      block.animateFigure &&
                      typeof window !== "undefined" && (
                        <CountUp
                          start={"439000"}
                          end={"999999"}
                          decimal="."
                          decimals="0"
                          separator=","
                          prefix="$"
                          easing="0.5, 1, 0.89, 1"
                          duration={10000}
                        />
                      )}
                  </div>
                  <div
                    className={cx(
                      "mx-auto",
                      "text-large",
                      "text-center",
                      "px-2",
                      "mt-3",
                      "font-sansRegular"
                    )}
                  >
                    <BlockContent blocks={block.body} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {style == "inset" && (
        <div
          className={cx(
            "grid",
            "grid-cols-4",
            "sm:grid-cols-20",
            "p-2",
            "md:p-0",
            "mx-auto",
            "gap-[5px]",
            "site-container"
          )}
        >
          {gridBlocks.map((block, idx) => {
            return (
              <div
                key={idx}
                className={cx("col-span-4", "sm:col-span-9", "grid-block", {
                  "sm:col-start-2": idx % 2 == 0,
                })}
              >
                <div className={cx("flip-card", "relative")}>
                  <div
                    className={cx(
                      "front",
                      "card-side",
                      `bg-white`,
                      "pt-gutterInset",
                      "px-gutterInset",
                      "cursor-pointer"
                    )}
                    onClick={() => setOpenGridBlock(idx)}
                    data-theme="white"
                  >
                    <div
                      className={cx(
                        "max-w-[100%]",
                        "mx-auto",
                        "aspect-landscape--disabled",
                        "relative"
                      )}
                    >
                      {block.image && (
                        <Photo
                          photo={block.image}
                          width={1200}
                          layout="static"
                        />
                      )}
                    </div>
                    <div
                      className={cx(
                        "max-w-[60%]",
                        "font-sansRegular",
                        "tracking-[0.04em]",
                        "mx-auto",
                        "text-subheader-2",
                        "uppercase",
                        "text-center",
                        "pt-10",
                        "pb-[21px]"
                      )}
                    >
                      {block.title}
                    </div>
                    {block.body && (
                      <button
                        className={cx(
                          "w-[28px]",
                          "h-[28px]",
                          "absolute",
                          "bottom-gutterInset",
                          "right-gutterInset",
                          "transition-transform",
                          {
                            "rotate-45": openGridBlock === idx,
                          }
                        )}
                        onClick={() => setOpenGridBlock(idx)}
                      >
                        <Icon
                          id={`open-circle-toggle-${idx}`}
                          name="CircleToggle"
                          viewBox="0 0 26 26"
                          color="#000000"
                          fill="transparent"
                        />
                      </button>
                    )}
                  </div>
                  <div
                    className={cx(
                      "back",
                      "p-3",
                      "absolute",
                      "top-0",
                      "left-0",
                      "w-full",
                      "h-full",
                      "z-20",
                      "flex",
                      "items-center",
                      "justify-center",
                      "card-side",
                      "pointer-events-none",
                      "transition-opacity",
                      "text-large",
                      "font-sansRegular",
                      "tracking-[0]",
                      "leading-[1.25]",
                      "cursor-pointer",
                      `bg-${block.backgroundColor.value}`,
                      {
                        "opacity-0": openGridBlock !== idx,
                        "opacity-100 pointer-events-auto":
                          openGridBlock === idx,
                      }
                    )}
                    onClick={() => setOpenGridBlock(false)}
                    data-theme={block.backgroundColor?.title.toLowerCase()}
                  >
                    <div
                      className={cx("content-wrap", "px-[8%]", "md:px-[15%]")}
                    >
                      <BlockContent blocks={block.body} />
                    </div>
                    <button
                      className={cx(
                        "w-[28px]",
                        "h-[28px",
                        "absolute",
                        "bottom-gutterInset",
                        "right-gutterInset",
                        "transition-transform",
                        {
                          "rotate-45": openGridBlock === idx,
                        }
                      )}
                      onClick={() => setOpenGridBlock(false)}
                    >
                      <Icon
                        id={`close-circle-toggle-${idx}`}
                        name="CircleToggle"
                        viewBox="0 0 26 26"
                        color="#000000"
                        fill="transparent"
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default Grid;
